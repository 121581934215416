import store from "@/store";
import moment from "moment";
const currencySymbolObject = {
  USD: "$",
  CAD: "$",
  AUD: "$",
  INR: "₹" || "&#8377;",
  EUR: "€",
};
export default {
  name: "Fields Format Helper",
  data() {
    return {};
  },
  methods: {
    getFormattedFieldData(field, form) {
      if (form[field.key] == null || form[field.key] == undefined) {
        return "-";
      }
      let inputType = field.input_type;
      if (field.input_type == "FORMULA") {
        if (field.validations?.currency) {
          inputType = "CURRENCY";
        } else if (field?.result_type) {
          inputType = field.result_type;
        }
      } else if (
        field.input_type == "PAYMENT_VARIABLE" ||
        field.input_type == "ENTITY_VARIABLE"
      ) {
        inputType = field.inputType;
      }
      let time1,time2,returnValue,currencyType,formattedValue;
      switch (inputType) {
        case "ENTITY":
          returnValue = form[`${field.key}/name`];
          break;
        case "DATE":
          returnValue =
            this.getFormattedDate(
              form[field.key]
            ) || "-";
          break;
        case "TIME":
          returnValue =
            this.getFormattedTime(
              form[field.key]
            ) || "-";
          break;
        case "DATE_TIME":
          returnValue = this.getFormattedDateTime(form[field.key]) || "-";
          break;
        case "TIME_RANGE":
          time1 =
            this.getFormattedTime(
              form[field.key][0],
            ) || "-";
          time2 =
            this.getFormattedTime(
              form[field.key][1],
            ) || "-";
          returnValue = `${time1} - ${time2}`;
          break;
        case "DATE_RANGE":
          time1 =
            this.getFormattedDate(
              form[field.key][0],
            ) || "-";
          time2 =
            this.getFormattedDate(
              form[field.key][1],
            ) || "-";
          returnValue = `${time1} to ${time2}`;
          break;
        case "DATE_TIME_RANGE":
          time1 =
            this.getFormattedDateTime(
              form[field.key][0],
            ) || "-";
            time2 =
            this.getFormattedDateTime(
              form[field.key][1],
            ) || "-";
          returnValue = `${time1} to ${time2}`;
          break;
        case "CURRENCY":
          currencyType = field.validations?.currency
            ? field.validations.currency
            : field.selected_fields[0].validations?.currency;
          if (!Number.isInteger(form[field.key])) {
            formattedValue = Number(form[field.key])?.toFixed(2);
          } else {
            formattedValue = form[field.key];
          }
          returnValue = `${currencySymbolObject[currencyType]}${formattedValue}`;
          break;
        case "LIST":
          if (field.list_data && field.list_data.length) {
            let data = field.list_data.find((e) => e.value == form[field.key]);
            returnValue = data.name
          }
          break;
        case "NUMBER":
          returnValue = form[field.key];
          if (field?.properties?.number_type == "DECIMAL") {
            returnValue = parseFloat(form[field.key])?.toFixed(2);
          } else if (field.decimals) {
            returnValue = parseFloat(form[field.key])?.toFixed(field.decimals);
          } else if (!Number.isInteger(form[field.key])) {
            returnValue = parseFloat(form[field.key])?.toFixed(2);
          }
          break;
        case "FORMULA":
          returnValue = parseFloat(form[field.key])?.toFixed(2);
          break;
        case "AGGREGATE_FUNCTION":
          returnValue = this.getDataFieldValue(field, form[field.key]);
          break;
        case "PHONE_COUNTRY_CODE":
          returnValue = form[`${field.key}_code`]["formatInternational"];
          break;
        default:
          returnValue = form[field.key];
          break;
      }
      return returnValue;
    },
    getFormattedDateTime(date) {
      if (!date) return "";
      const format = store.getters["auth/getDefaultDateFormat"]
        ? store.getters["auth/getDefaultDateFormat"] + " HH:mm:ss"
        : +"MM-DD-YYYY HH:mm";
      return moment(date, moment.ISO_8601, true).isValid()
        ? moment(date).format(format)
        : date;
    },
    getFormattedTime(date) {
      if (!date) return "";
      const format = store.getters["auth/getDefaultDateFormat"]
        ? " HH:mm:ss"
        : +"MM-DD-YYYY HH:mm:ss";
      return moment(date, moment.ISO_8601, true).isValid()
        ? moment(date).format(format)
        : date;
    },
    getFormattedDate(date) {
      if (!date) return "";
      const format = store.getters["auth/getDefaultDateFormat"]
        ? store.getters["auth/getDefaultDateFormat"]
        : "MM-DD-YYYY";
      return moment(date, moment.ISO_8601, true).isValid()
        ? moment(date).format(format)
        : date;
    },
    getDataFieldValue(field, value) {
      if (!value) {
        value = 0;
      }
      if (field?.properties?.number_type == "DECIMAL") {
        value = parseFloat(value)?.toFixed(2);
      } else if (field.decimals) {
        value = parseFloat(value)?.toFixed(field.decimals);
      } else if (!Number.isInteger(value)) {
        value = parseFloat(value)?.toFixed(2);
      }
      if (field?.inputType == "CURRENCY") {
        let type = field.validations.currency
          ? field.validations.currency
          : "USD";
        value = `${currencySymbolObject[type]}${value}`;
      } else if (
        field?.inputType == "AGGREGATE_FUNCTION" ||
        field?.inputType == "FORMULA"
      ) {
        let isCurrencyField = (field.selected_fields || []).find((el) => {
          if (
            el?.inputType == "CURRENCY" ||
            el?.input_type == "CURRENCY" ||
            el?.result_type == "CURRENCY"
          ) {
            return true;
          }
        });
        if (isCurrencyField) {
          if (field?.inputType == "AGGREGATE_FUNCTION") {
            // let dataTableField = this.getField(
            //   field.template_id + "#" + isCurrencyField.data_table_key
            // );
            // if (dataTableField) {
            //   let rField = (dataTableField.data_table_columns || []).find(
            //     (e) => e.key == isCurrencyField.key
            //   );
            //   if (rField?.validations) {
            //     isCurrencyField["validations"] = rField.validations;
            //   }
            // }
          } else {
            if (field?.validations) {
              isCurrencyField["validations"] = field.validations;
            }
          }
          let type = isCurrencyField?.validations?.currency || "USD";
          value = `${currencySymbolObject[type]}${value}`;
        }
      }
      return value;
    },
    getParsedHtmlString(form, string, fields) {
      if(!string || !fields?.length) {
        return ""
      }
      let keyAndFields = {};
      fields.map((e) => {
        keyAndFields[e.key] = e
      });
      string =
      string.replace(
        /\[\[(.*?)\]\]/g,
        (match, key) => {
          const template = keyAndFields[key];
          if(!template) {
            return "-";
          }
          let value = this.getFormattedFieldData(template,form);
          return value ?? '-';
        }
      );
      return string
    }
  },
};
